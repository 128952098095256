import axios from "axios";
import {
  FixedAssetAddition,
  FixedAssetAdditionsWithPagination,
} from "types/FixedAssetAddition";

export async function getFixedAssetAdditions(
  page: number,
  filters: Record<string, any>,
  pageSize: number,
  sorter: string,
) {
  const { data } = await axios.get<FixedAssetAdditionsWithPagination>(
    `/tenant/fixed_asset_additions.json`,
    {
      params: {
        page,
        per_page: pageSize,
        "q[s]": sorter,
        ...filters,
      },
    },
  );
  return data;
}

export async function getFixedAssetAddition(
  fixedAssetAdditionID: number | string,
) {
  const { data } = await axios.get<FixedAssetAddition>(
    `/tenant/fixed_asset_additions/${fixedAssetAdditionID}.json`,
  );
  return data;
}
