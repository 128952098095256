import BookKeepingIcon_I from 'assets/icons/bookkeeping_I.svg';
import BookKeepingIcon_II from 'assets/icons/bookkeeping_II.svg';
import BookKeepingIcon_III from 'assets/icons/bookkeeping_III.svg';
import BookKeepingIcon_IV from 'assets/icons/bookkeeping_IV.svg';
import BookKeepingIcon_V from 'assets/icons/bookkeeping_V.svg';
import BookKeepingIcon_VI from 'assets/icons/bookkeeping_VI.svg';



interface BookkeepingInfoItem {
  title: string;
  content: string;
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}
export const bookkeepingInfoItems: BookkeepingInfoItem[] = [
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.I.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.I.content',
    Icon: BookKeepingIcon_I
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.II.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.II.content',
    Icon: BookKeepingIcon_II
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.III.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.III.content',
    Icon: BookKeepingIcon_III
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.IV.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.IV.content',
    Icon: BookKeepingIcon_IV
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.V.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.V.content',
    Icon: BookKeepingIcon_V
  },
  {
    title: 'activerecord.attributes.professional_services.bookkeeping.info.VI.title',
    content: 'activerecord.attributes.professional_services.bookkeeping.info.VI.content',
    Icon: BookKeepingIcon_VI
  },
]