import { Layout, theme, Space, Divider, Typography } from 'antd';
const { Title } = Typography;

const { Content } = Layout;
const { useToken } = theme;

const QContent: React.FC<{children: React.ReactNode | React.ReactNode[], title: string}> = ({ children, title }) => {

    const { token } = useToken();
    
    return (
        <>
            <Content
                style={{
                    paddingInline: token.paddingMD,
                    paddingTop: token.paddingLG,
                    paddingBottom: 2*token.paddingMD,
                    backgroundColor: token.colorBgContainer,
                    margin: 0,
                    minHeight: 280
                }}
            >
                <Divider orientation='left'>
                    <Title level={2} style={{ marginTop: 0 }}>{title}</Title>
                </Divider>
                <Space style={{display: 'flex'}} direction="vertical" size={token.paddingLG}>
                    {children}
                </Space>
            </Content>
        </>
        
    )
}



export default QContent;