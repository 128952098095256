import { QueryKeyType } from "types/query-keys";

export const fixedAssetAdditionKeys: QueryKeyType = {
  all: ["fixed-asset-additions"],
  lists: () => [...fixedAssetAdditionKeys.all, "lists"],
  list: (filters: any) => [
    ...(fixedAssetAdditionKeys.lists ?? (() => fixedAssetAdditionKeys.all))(),
    filters,
  ],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(fixedAssetAdditionKeys.list ?? (() => fixedAssetAdditionKeys.all))(
      filters,
    ),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [
    ...fixedAssetAdditionKeys.all,
    "details",
    id,
  ],
};

export const currentUserKeys: QueryKeyType = {
  all: ["current-user"],
};
