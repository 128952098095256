import { useTranslation } from 'react-i18next'
import QServiceLayout from 'components/layout/QServiceLayout';
import { Row, Col, Typography, Flex, Button, Modal, Result, message, Divider } from 'antd';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useState } from 'react';
import Poster from 'components/tenant/additional_services/Poster';
import PosterSrc from 'assets/images/bookkeeping_poster.jpg'
import { bookkeepingInfoItems } from 'tokens/additional_services';
import InfoItem from 'components/tenant/additional_services/InfoItem';
import QTahseelLogo from 'assets/logos/QTahseel Logo.png';

const { Title, Paragraph } = Typography;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.professional_services'),
      },
      {
          title: t('activerecord.attributes.layout.bookkeeping'),
      },
  ]
}

const BookKeeping = () => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  const [content, setContent] = useState<null | React.ReactNode>(null);
  const [messageApi, messageContext] = message.useMessage();

  const mutation = useMutation({
    mutationKey: ['quote'],
    mutationFn: async () => {
      return await (axios.post('/tenant/additional_services', {
        service_type: "bookkeeping"
      }))
    },
    onSuccess: () => {
      setContent(
        <Result
          status="success"
          title={t("activerecord.attributes.vat_services.setup.request_successfully_submitted")}
          subTitle={t("activerecord.attributes.vat_services.setup.request_submitted_description")}
          extra={[
            <Button type="primary" key="ok" onClick={() => setContent(null)}>
              {t("activerecord.attributes.vat_services.setup.ok_thanks")}
            </Button>,
          ]}
        />
      )
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      })
    }
  })
  
  return (
    <QServiceLayout title={t('activerecord.attributes.layout.bookkeeping')} actionBtns={null} breadCrumbData={breadCrumbData} messageHolder={messageContext} >
      <Row gutter={[32, 32]}>
        <Col md={12}>
          <Flex vertical justify='center' style={{ height: '100%' }}>
            <img src={QTahseelLogo} width={"50%"} />
            <Title level={4}>{t('activerecord.attributes.professional_services.bookkeeping.title')}</Title>
            <Paragraph style={{ textAlign: 'justify' }}>
              {t('activerecord.attributes.professional_services.bookkeeping.description')}
            </Paragraph>
            <div>
              <Button type='primary' onClick={() => mutation.mutate()}>
                {t('activerecord.attributes.professional_services.bookkeeping.action')}
              </Button>
            </div>
          </Flex>
        </Col>
        <Col md={12}>
          <Poster src={PosterSrc} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[32, 32]}>
        {bookkeepingInfoItems.map((item, idx) => (
          <InfoItem key={idx} title={`${idx + 1}. ${t(item.title)}`} content={t(item.content)} Icon={item.Icon} />
        ))}
      </Row>
      <Modal footer={null} open={Boolean(content)} closable={false}>
        {content}
      </Modal>
    </QServiceLayout>
  )
}

export default BookKeeping