import BackButton from "components/layout/BackButton";
import QPageLayout from "components/layout/QPageLayout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Skeleton, Form, Input, Flex, Button } from "antd";
import { useMutation, useQuery } from "react-query";
import { getDimension } from "api/dimensions";
import type { DimensionType, DimensionFormDataTypes } from "types/dimensions";
import { selectConfigurationModelHandler } from "helpers/dimensions";
import { CONFIGURATION_OPTIONS } from "tokens/dimensions";
import DimensionConfiguration from "components/tenant/dimensions/DimensionConfiguration";
import axios from "axios";

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.settings'),
      },
      {
          title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name_plural')}</a>,
      },
      {
          title: t('activerecord.attributes.dimension.edit.title'),
      },
  ]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 3 }
    
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 9 },
  },
};

const Edit = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [checkedList, setCheckedList] = useState<unknown[]>([]);
  const { data, isLoading } = useQuery<DimensionType>({
    queryKey: ["dimension"],
    queryFn: getDimension.bind(null, { dimensionID: id })
  });

  const mutation = useMutation<unknown, unknown, DimensionFormDataTypes>({
    mutationKey: ["editDimension"],
    mutationFn: (values) => {
      const {dimension_configuration, ...dimension} = values;
      return axios.patch(`/tenant/dimensions/${id}.json`, { dimension, dimension_configuration });
    },
    onSuccess: () => {
      location.replace('/tenant/dimensions')
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    }
  })

  const valuesChangeHandler = (values: DimensionFormDataTypes) => {    
    switch(Object.keys(values)[0]) {
      case "dimension_configuration":
        selectConfigurationModelHandler(values.dimension_configuration, CONFIGURATION_OPTIONS, setCheckedList)
        break;
    }
  }

  useEffect(() => {
    if(data) {
      for(const [key, value] of Object.entries(data)) {
        form.setFieldValue(key, value)
      }
    }
  }, [ data ])

  
  return (
    <QPageLayout
      title={t("activerecord.attributes.dimension.edit.title")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
      messageHolder={contextHolder}
    >
      {isLoading? 
        <Skeleton active />
        :
        (
          <Form
          labelWrap
          form={form}
          name="code"
          onFinish={() => mutation.mutate(form.getFieldsValue() as DimensionFormDataTypes)}
          scrollToFirstError
          onValuesChange={valuesChangeHandler}
          validateMessages={{
            required: t("activerecord.attributes.dimension.create_code.errors.required") + " ${label}"
          }}
          {...formItemLayout}
        >
            <Form.Item
              name="code"
              label={t("activerecord.attributes.dimension.create_code.code")}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="name_ar"
              rules={[
                {
                  required: true
                }
              ]}
              label={t("activerecord.attributes.dimension.create_code.name_ar")}
            >
              <Input maxLength={25} />
            </Form.Item>
            <Form.Item
              name="name_en"
              rules={[
                {
                  required: true
                }
              ]}
              label={t("activerecord.attributes.dimension.create_code.name_en")}
            >
              <Input maxLength={25} />
            </Form.Item>
            <Form.Item name="description" label={t("activerecord.attributes.dimension.create_code.description")}>
              <Input.TextArea maxLength={100} rows={4} />
            </Form.Item>
            <DimensionConfiguration dimension={data} setCheckedList={setCheckedList} checkedList={checkedList} />
            <Form.Item wrapperCol={{ xs: { offset: 0 }, lg: { offset: 12 } }}>
              <Flex gap="small" justify='end'>
                <Button type="primary" disabled={mutation.isLoading || mutation.isSuccess ? true : false} htmlType="submit">
                  {t("forms.buttons.save")}
                </Button>
                <Button danger href='/tenant/dimensions'>
                  {t("forms.buttons.cancel")}
                </Button>
              </Flex>
            </Form.Item>
          </Form>
        )
      }
    </QPageLayout>
  );
};

export default Edit;
