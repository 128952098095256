import { useQuery } from "react-query";
import { Permissions } from "types/app";

export const usePermission = (moduleName: string) => {
  const { data } = useQuery<Permissions, Error>(
    ["permissions", moduleName],
    async () => {
      const response = await fetch(`/tenant/permissions/${moduleName}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json() as Promise<Permissions>;
    },
  );

  if (data) return data;
  else
    return {
      read: false,
      write: false,
      delete: false,
      approve: false,
    };
};
