import { Radio, Form, Tooltip } from 'antd';
import ConfigurationOptions from './ConfigurationOptions';
import { useTranslation } from 'react-i18next';
import { DimensionType, GlobalDimensionResponse } from 'types/dimensions';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import i18next from 'i18next';

interface Props {
  dimension?: DimensionType;
  setCheckedList: React.Dispatch<React.SetStateAction<unknown[]>>;
  checkedList: unknown[];
}

const DimensionConfiguration = ({ dimension, setCheckedList, checkedList }: Props) => {  
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const isGlobal = Form.useWatch<boolean>("global", form);
  const [canBeGlobal, setCanBeGlobal] = useState(false);

  useQuery({
    queryKey: ['globalCount'],
    queryFn: async () => {
      return (await axios.get<GlobalDimensionResponse>('/tenant/dimensions.json?per_page=15&page=1&q[global_eq]=true')).data
    },
    onSuccess: (data) => {
      const { records } = data;
      setCanBeGlobal(Boolean(records.length < 2))
    }
  })

  useEffect(() => {
    if(dimension) {
      form.setFieldValue('global', dimension.global)
    }
  }, [ dimension ])
  
  return (
    <>
      <Tooltip title={t('activerecord.attributes.dimension.configuration.errors.update_global')} placement={i18next.language === 'en'? 'right' : 'left'}>
        <Form.Item labelCol={{xs: {span: 24}, lg: {span: 3}}} wrapperCol={{xs: {span: 24}, lg: {span: 9}}} label={t("activerecord.attributes.dimension.configuration.title")} name="global" initialValue={false}>
            <Radio.Group>
                <Radio disabled={!canBeGlobal} style={{fontWeight: 'normal'}} value={true}>
                  <Tooltip title={!canBeGlobal && t('activerecord.attributes.dimension.configuration.errors.limit')}>
                    {t('activerecord.attributes.dimension.configuration.global')}
                  </Tooltip>
                </Radio>
              <Radio style={{fontWeight: 'normal'}} value={false}>{t('activerecord.attributes.dimension.configuration.shortcut')}</Radio>
            </Radio.Group>
        </Form.Item>
      </Tooltip>
      {!isGlobal && <ConfigurationOptions dimensionID={dimension?.id} setCheckedList={setCheckedList} checkedList={checkedList} />}
    </>
  )
}

export default DimensionConfiguration