import React from "react";

export const selectConfigurationModelHandler = (checkedValues: unknown[], options: string[], setCheckedList: React.Dispatch<React.SetStateAction<unknown[]>>) => {
  const allIdx = checkedValues.indexOf(""); 
  if(allIdx > -1 && checkedValues.length < options.length) {
    return setCheckedList(["", ...options]);
  } else if(allIdx > -1 && checkedValues.length >= options.length) {
    checkedValues.splice(allIdx, 1);
  } else if(allIdx === -1 && checkedValues.length === options.length) {
    checkedValues.push("");
  }
  setCheckedList([...checkedValues] as string[])
};