import React, {useMemo, useState, useContext, useCallback, useEffect} from 'react'
import type { MenuProps } from 'antd';
import { Typography, Menu, theme, Button, Tag, ConfigProvider, Flex }  from 'antd';
import type {ItemData as RawItemData} from 'types/layout';
import QLayoutContext from 'contexts/Layout';
import { useTranslation } from 'react-i18next';
import styles from 'styles/layout.scss';
import ReactGA from 'react-ga4';
import GAMap from 'tokens/GAMap.json';
import { TFunction } from 'i18next';
import { tagMap, iconMap, labelMap } from 'tokens/layout';
import { PlusOutlined } from '@ant-design/icons';

const {useToken} = theme;
const {Link} = Typography;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    style?: React.CSSProperties,
    clickAction?: () => void,
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      style: {height: 'auto', ...style},
      className: styles.q_ant_menu__sub_item,
      onTitleClick: clickAction
    } as MenuItem;
  }

function getItems(broken: boolean, isMobileRequest: boolean, t: TFunction<'translation', undefined>, startIntro: () => void, setOpenKeys: (ids: string[]) => void, rawItem: RawItemData, style: React.CSSProperties = {}): MenuItem {

    if(!rawItem || !rawItem.props) {
        return null;
    }


    const {props, children, new_entity, tag} = rawItem;
    let actionElement = null;
    const { id } = props;
    let { href } = props;

    let showMeHow = () => {};
    if(id === 'sidepanel_show_me_how') {
        if(broken) return null;
        showMeHow = startIntro;
    }

    const moduleName = id.split('__')?.[1] ?? id.split('_').slice(1).join('_');
    let [path, newPath, dataToggle, dataTarget] = [href, new_entity?.link, "", ""];
    let mobileWarning: (module: string) => void = () => {};
    
    if(isMobileRequest && href && href !== '#' && !id.startsWith('sidepanel_settings') ) {
        [path, newPath, dataToggle, dataTarget] = ['#', '#', 'modal', `#${moduleName}`]
        mobileWarning = (module) => window.replaceModuleWords(module);
    }

    let TagElement: () => null | React.ReactNode = () => null;
    if(tag) {
        const tagData = tagMap.get(tag.type)!;
        TagElement = () => <Tag color={tagData.color}>{t(tagData.label)}</Tag>
    }

    if(new_entity?.allowed) {

        const newClickAction = () => {
            const createAction = (GAMap.sidemenu[id] as { create: string })?.create;
            if(createAction) {
                ReactGA.event({
                    category: GAMap.sidemenu.CATEGORY,
                    action: createAction
                });
            }
            mobileWarning(`new_${moduleName}`)
        }

        actionElement = (
            <Button type="link" href={newPath} data-toggle={dataToggle} data-target={`#new_${moduleName}`} onClick={newClickAction} style={{color: 'inherit', display: 'inline'}} icon={ <PlusOutlined /> } />
        )
    }

    href = href || '#';

    let itemChildren: MenuItem[] | undefined = undefined;
    if(children?.length ?? 0 > 0) {
        itemChildren = [];
        children!.forEach(child => {
            itemChildren!.push(getItems(broken, isMobileRequest, t, startIntro, (() => {}), child, {display: 'flex'}))
        })
    }

    const clickAction = () => {
        const viewAction = (GAMap.sidemenu[id] as { view: string })?.view;
        if(viewAction) {
            ReactGA.event({
                category: GAMap.sidemenu.CATEGORY,
                action: viewAction
            });
        }
        showMeHow();
        mobileWarning(moduleName);
    }

    const openInNewTab = ['sidepanel_knowledge_base', 'sidepanel_advanced_accounting_portal']
    return getItem(
        <Flex gap={4} style={{display: 'inline-flex', justifyContent: 'space-between', lineHeight: 1.2, width: '100%'}}>
            <Link href={path} data-toggle={dataToggle} data-target={dataTarget} onClick={clickAction} style={{color: 'inherit', whiteSpace: 'break-spaces', wordBreak: 'unset', flex: 1}} target={openInNewTab.includes(id)? '_blank' : '_self'}>
                <Flex gap={4} justify='space-between' align='center'>
                    {t(labelMap.get(id)!)}
                    <TagElement />
                </Flex>
            </Link>
            {actionElement}
        </Flex>,
        id,
        iconMap.get(id),
        itemChildren,
        style,
        setOpenKeys.bind(null, [id])
    )
}

interface SideMenuProps {
    startIntro: () => void;
    collapsed: boolean;
    broken: boolean;
}

const SideMenu = ({startIntro, collapsed, broken}: SideMenuProps) => {
    const { t } = useTranslation();
    const { sideMenuData, currentActiveItem, currentActiveMenu, isMobileRequest } = useContext(QLayoutContext);
    const [openKeys, setOpenKeys] = useState<string[] | undefined>([currentActiveMenu]);
    const [lastOpenedKeys, setLastOpenedKeys] = useState<string[] | undefined>([currentActiveMenu])

    const {token} = useToken();

    const openKey = useCallback((keys: string[]) => {
        setOpenKeys((prevState) => prevState?.[0] === keys[0]? [] : keys)
    }, [ openKeys ])

    useEffect(() => {
        if(collapsed) {
            setLastOpenedKeys(openKeys);
            setOpenKeys(undefined);
        } else {
            setOpenKeys(lastOpenedKeys)
        }
    }, [collapsed])

    const items = useMemo<MenuItem[]>(() => sideMenuData?.map<MenuItem>((rawItem) => getItems(broken, isMobileRequest, t, startIntro, openKey, rawItem)), [sideMenuData, isMobileRequest, broken]);


    return (
        <>
            <ConfigProvider theme={{components: {
                Menu: {
                    darkItemBg: token.colorPrimary,
                    darkSubMenuItemBg: token.colorInfo,
                    darkItemSelectedBg: token.colorInfo
                }
            }}}>
                <Menu items={items}
                    style={{width: '100%'}}
                    theme="dark"
                    openKeys={openKeys}
                    mode="inline"
                    selectedKeys={[currentActiveItem]}
                />
            </ConfigProvider>
        </>
    )
}

export default SideMenu
