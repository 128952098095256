import axios from "axios";
import { QueryFunctionContext } from "react-query";
import { DimensionAssignableResponse, DimensionConfigs, DimensionType, DimensionValueResponse } from "types/dimensions";


export const getDimension = async({ dimensionID }: {dimensionID: string}): Promise<DimensionType> => {
  return (await axios.get<DimensionType>(`/tenant/dimensions/${dimensionID}.json`)).data
}

export const getDimensions = async (props: {page?: number, 'q[sorts]': string[]}): Promise<DimensionType[]> => {
  const queryParams = new URLSearchParams();
  for(const [key, value] of Object.entries(props)) {
    if(Array.isArray(value)) {
      value.forEach(val => queryParams.append(`${key}[]`, String(val)))
      continue;
    }
    queryParams.set(key, String(value));
  }
  return (await axios.get<DimensionType[]>(`/tenant/dimensions.json?${queryParams.toString()}`)).data
}

export const getConfiguration = async({ dimensionID }: {dimensionID: string}) => {
  return (await axios.get<DimensionConfigs[]>(`/tenant/dimensions/${dimensionID}/configurations.json?q[enabled_eq]=true`)).data
}

export const updateConfigurations = async (): Promise<DimensionType> => {
  return (await axios.get<DimensionType>(`/tenant/dimensions.json`)).data
}

export const getParentDimensionValues = async ({ dimensionID }: { dimensionID: string }, { pageParam }: QueryFunctionContext<string[], number>) => {  
  const { data } = await axios.get<DimensionValueResponse>(`/tenant/dimensions/${dimensionID}/values.json?page=${pageParam ?? 1}&q[value_type_eq]=parent_value`);
  return data;
}

interface ValuesProps { 
  dimensionID: number; 
  cdType: string;
  page: number;
  type: string; 
  parentID?: number | null | undefined; 
  perPage?: number;
  valueID: string;
  disabledDimensionValues?: boolean;
  lineItem?: number | string;
}

export const getDimensionValues = async ({ dimensionID, page=1, parentID, cdType='', perPage=100, type='', valueID='', disabledDimensionValues, lineItem='' }: ValuesProps) => {  
  let parentQueryParam = '';
  if(typeof parentID !== 'undefined') {
    parentQueryParam = parentID == null? 'q[parent_id_null]=true' : `q[parent_id_eq]=${parentID}`;
  }
  
  let disabledQueryParam = '';
  if(typeof disabledDimensionValues !== 'undefined') {
    disabledQueryParam = `q[activated_eq]=${disabledDimensionValues}`;
  }

  const { data } = await axios.get<DimensionValueResponse>(`/tenant/dimensions/${dimensionID}/values.json?per_page=${perPage}&page=${page}&q[available_for_cd]=${cdType}&q[value_type_eq]=${type}&q[id_eq]=${valueID}&q[available_for_line_item]=${lineItem}&${parentQueryParam}&${disabledQueryParam}`);
  return data;
}

export const getActivatedDimensionValues = ({...props}: ValuesProps) => getDimensionValues({...props, disabledDimensionValues: true})

export const getValuesByLineItem = async ({ lineItemID, cdType }: { lineItemID: string, cdType: string }) => {
  const { data } = await axios.get<DimensionAssignableResponse>(`/tenant/dimension_value_assignables.json?q[by_external_line_item][line_item_id]=${lineItemID}&q[by_external_line_item][cd_type]=${cdType}`);
  return data;
}
