import {
  FixedAssetAdditionFilterForm,
  FixedAssetAdditionActionButtons,
} from "components/tenant/FixedAssetAdditions";
import { useQuery } from "react-query";
import { currentUserKeys } from "tokens/query-keys";
import { getCurrentUser } from "api/CurrentUser";
import { Skeleton } from "antd";
import UserContext from "contexts/UserContext";
import { useState } from "react";
import { Table, Tag, Button, Flex, Modal, Tooltip } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import NoBudgetData from "assets/icons/NoBudgetData.svg";

import { getFixedAssetAdditions } from "api/FixedAssetAdditions";
import QEmptyIndex from "components/layout/emptyIndex";
import QPageLayout from "components/layout/QPageLayout";
import { type FixedAssetAddition } from "types/FixedAssetAddition";

import { fixedAssetAdditionKeys } from "tokens/query-keys";
import ServerError from "components/Errors/ServerError";
import { SorterResult } from "antd/es/table/interface";
import { usePermission } from "hooks/index";
import { useInteractiveFilter } from "hooks/app";
import { TablePaginationConfig } from "antd/lib";

const tagColor = (status: string): string => {
  if (status === "draft") {
    return "orange-inverse";
  } else if (status === "awaiting") {
    return "blue-inverse";
  } else {
    return "green-inverse";
  }
};

const FixedAssetAdditions = () => {
  const userQuery = useQuery({
    queryKey: currentUserKeys.all,
    queryFn: getCurrentUser,
  });

  const { t } = useTranslation();
  const permission = usePermission("fixedAssetAddition");

  const [filters, setFilters] = useState<Record<string, any>>({});
  const [filterApplied, setFilterApplied] = useState(false);
  const interactiveFilter = useInteractiveFilter(
    {
      setFilterUpdate: setFilterApplied,
      path: "/tenant/fixed_asset_additions.json",
    },
    [filterApplied],
  );

  const fieldOrder = interactiveFilter.sorter.get()
    ? interactiveFilter.sorter.get()
    : { field: "created_at", order: "desc" };

  const sortOrder = `${fieldOrder?.field} ${fieldOrder?.order}`;
  const { data, isLoading, isError } = useQuery<
    {
      fixed_asset_additions: FixedAssetAddition[];
      pagination: { total_entries: number };
    },
    Error
  >({
    queryKey: fixedAssetAdditionKeys.paginate?.(
      filters,
      interactiveFilter.page.get(),
      interactiveFilter.pageSize.get(),
      sortOrder,
    ),
    queryFn: () =>
      getFixedAssetAdditions(
        interactiveFilter.page.get(),
        filters,
        interactiveFilter.pageSize.get(),
        sortOrder,
      ),
  });

  const breadCrumbData = [
    {
      title: (
        <a href="/tenant/fixed_assets">
          {t("activerecord.attributes.layout.fixed_assets")}
        </a>
      ),
    },
    {
      title: t("fixed_asset_additions.layout.title"),
    },
  ];

  if (isError) {
    return (
      <QPageLayout
        title={t("fixed_asset_additions.layout.title")}
        actionBtns={<FixedAssetAdditionActionButtons />}
        breadCrumbData={breadCrumbData}
      >
        <ServerError />
      </QPageLayout>
    );
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _data: any,
    sorter:
      | SorterResult<FixedAssetAddition>
      | SorterResult<FixedAssetAddition>[],
  ) => {
    if (!Array.isArray(sorter)) {
      interactiveFilter.sorter.set({
        field: sorter.columnKey,
        order: sorter.order,
      });
    }

    interactiveFilter.page.set(pagination.current ?? 1);
    interactiveFilter.pageSize.set(pagination.pageSize ?? 20);
  };

  const handleFilterSubmit = (values: unknown) => {
    interactiveFilter.update();
    setFilters(values as Record<string, any>);
  };

  const handleReset = () => {
    interactiveFilter.update();
    setFilters({});
  };

  const handleDelete = (record: FixedAssetAddition) => {
    if (!record.is_deletable) {
      Modal.error({
        title: t("fixed_asset_additions.layout.form.delete_restricted"),
        content: t(
          "fixed_asset_additions.layout.form.delete_restricted_message",
        ),
        closable: true,
        footer: null,
      });

      return;
    }

    Modal.confirm({
      content: t("templates.sure_modal"),
      okText: t("forms.buttons.delete"),
    });
  };

  if (userQuery.isLoading || isLoading) return <Skeleton />;

  const columns = [
    {
      title: t("activerecord.attributes.fixed_asset_addition.reference"),
      dataIndex: "reference",
      key: "reference",
      sorter: true,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.date"),
      dataIndex: "date",
      key: "date",
      sorter: true,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.asset_name"),
      dataIndex: "fixed_asset_name",
      key: "fixed_asset_name",
      sorter: false,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.asset_reference"),
      dataIndex: "asset_registry_sku",
      key: "asset_registry_sku",
      sorter: false,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.addition_type"),
      dataIndex: "addition_type",
      key: "addition_type",
      sorter: false,
      render: (text: string | null) => {
        if (text === null) {
          return null;
        }

        return t(
          `activerecord.attributes.fixed_asset_addition.addition_types.${text}`,
        );
      },
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.status"),
      dataIndex: "status",
      key: "status",
      sorter: false,
      render: (text: string) => {
        if (text === null) {
          return null;
        }

        return (
          <Tag color={tagColor(text)}>
            {t(`activerecord.attributes.fixed_asset_addition.statuses.${text}`)}
          </Tag>
        );
      },
    },
    {
      title: t("activerecord.attributes.fixed_asset.action"),
      dataIndex: "action",
      render: (_: any, record: FixedAssetAddition) => {
        return (
          <Flex wrap gap="small">
            <Tooltip placement="bottom" title={t("tooltip.view")}>
              <a href={`/tenant/fixed_asset_additions/${record.id}`}>
                <Button icon={<EyeOutlined />} />
              </a>
            </Tooltip>
            {permission?.write &&
              (record.status === "draft" || record.status === "awaiting") && (
                <Tooltip placement="bottom" title={t("tooltip.edit")}>
                  <a href={`/tenant/fixed_asset_additions/${record.id}/edit`}>
                    <Button icon={<EditOutlined />} />
                  </a>
                </Tooltip>
              )}
            {permission?.delete && (
              <Tooltip placement="bottom" title={t("tooltip.delete")}>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  ghost={!(record.is_deletable ?? true)}
                  onClick={() => handleDelete(record)}
                  type={record.is_deletable ? "default" : "dashed"}
                />
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <UserContext.Provider value={userQuery.data || null}>
      <QPageLayout
        title={t("fixed_asset_additions.layout.title")}
        actionBtns={<FixedAssetAdditionActionButtons />}
        breadCrumbData={breadCrumbData}
      >
        {Object.keys(filters).length === 0 &&
        !isLoading &&
        permission?.read &&
        data?.fixed_asset_additions?.length === 0 ? (
          <QEmptyIndex
            title={t("dashboard.information.fixed_asset_additions.title")}
            subTitle={t(
              "dashboard.information.fixed_asset_additions.description",
            )}
            Icon={NoBudgetData}
          >
            <></>
          </QEmptyIndex>
        ) : (
          <>
            <FixedAssetAdditionFilterForm
              onSubmit={handleFilterSubmit}
              onReset={handleReset}
            />
            <Table
              columns={columns}
              dataSource={data?.fixed_asset_additions}
              pagination={{
                current: interactiveFilter.page.get(),
                pageSize: interactiveFilter.pageSize.get(),
                total: data?.pagination.total_entries,
                showSizeChanger: true,
                pageSizeOptions: ["5", "15", "50", "100"],
              }}
              loading={isLoading}
              onChange={handleTableChange}
              rowKey="id"
              locale={{
                emptyText: t(
                  "activerecord.attributes.balance_sheet.no_data_found",
                ),
              }}
            />
          </>
        )}
      </QPageLayout>
    </UserContext.Provider>
  );
};

export default FixedAssetAdditions;
