import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Space } from "antd";
import { useTranslation } from "react-i18next";

import { usePermission } from "hooks/index";

const FixedAssetAdditionActionButtons = () => {
  const { t } = useTranslation();
  const permission = usePermission("fixedAssetAddition");
  const assetClassificationPermission = usePermission("assetClassification");

  return (
    <Flex vertical gap={10} align="end">
      <Space>
        {permission.write && assetClassificationPermission?.read && (
          <a href="/tenant/fixed_asset_additions/new">
            <Button icon={<PlusOutlined />} type="primary">
              {t("fixed_asset_additions.layout.new")}
            </Button>
          </a>
        )}
      </Space>
    </Flex>
  );
};

export { FixedAssetAdditionActionButtons };
