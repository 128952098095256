import { Form, Space, Input, Select, Button, DatePicker, Row } from "antd";
import { FilterOutlined, RedoOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";

const { Option } = Select;

interface FilterFormValues {
  "q[reference_cont]": string;
  "q[asset_registry_fixed_asset_name_or_asset_registry_fixed_asset_en_name_cont]": string;
  "q[asset_registry_sku_cont]": string;
  "q[date_gteq]": string;
  "q[date_lteq]": string;
  "date-range": Dayjs[] | null;
  "q[addition_type_eq]": string;
}

const FixedAssetAdditionFilterForm = ({
  onSubmit,
  onReset,
}: {
  onSubmit?: (formValues: any) => void;
  onReset?: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleFilterSubmit = () => {
    form
      .validateFields()
      .then((values: FilterFormValues) => {
        let dateFrom = "";
        let dateTo = "";
        if (values["date-range"]) {
          dateFrom = dayjs(values["date-range"][0]).format("YYYY-MM-DD");
          dateTo = dayjs(values["date-range"][1]).format("YYYY-MM-DD");
        }
        onSubmit?.({
          ...values,
          "q[date_gteq]": dateFrom,
          "q[date_lteq]": dateTo,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReset = () => {
    form.resetFields();
    onReset?.();
  };

  return (
    <Form form={form} onFinish={handleFilterSubmit}>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Space>
          <Form.Item name="q[reference_cont]">
            <Input
              placeholder={t(
                "activerecord.attributes.fixed_asset_addition.reference",
              )}
            />
          </Form.Item>
          <Form.Item name="q[asset_registry_fixed_asset_name_or_asset_registry_fixed_asset_en_name_cont]">
            <Input
              placeholder={t(
                "activerecord.attributes.fixed_asset_addition.asset_name",
              )}
            />
          </Form.Item>
          <Form.Item name="q[asset_registry_sku_cont]">
            <Input
              placeholder={t(
                "activerecord.attributes.fixed_asset_addition.asset_reference",
              )}
            />
          </Form.Item>
          <Form.Item name="date-range">
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item name="q[addition_type_eq]" wrapperCol={{ span: 30 }}>
            <Select
              placeholder={t(
                "activerecord.attributes.fixed_asset_addition.addition_type",
              )}
            >
              <Option value="0">
                {t(
                  "activerecord.attributes.fixed_asset_addition.addition_types.asset_merge",
                )}
              </Option>
              <Option value="1">
                {t(
                  "activerecord.attributes.fixed_asset_addition.addition_types.link_bill",
                )}
              </Option>
              <Option value="2">
                {t(
                  "activerecord.attributes.fixed_asset_addition.addition_types.create_bill",
                )}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button icon={<FilterOutlined />} type="primary" htmlType="submit">
              {t("forms.buttons.filter")}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={<RedoOutlined />} onClick={handleReset}>
              {t("forms.buttons.reset")}
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  );
};

export { FixedAssetAdditionFilterForm };
