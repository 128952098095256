import { useTranslation } from 'react-i18next'
import QServiceLayout from 'components/layout/QServiceLayout';
import { Row, Col, Typography, Flex } from 'antd';

const { Title, Paragraph } = Typography;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.professional_services'),
      },
      {
          title: t('activerecord.attributes.layout.qoyod_academy'),
      },
  ]
}

const QoyodAcademy = () => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  
  return (
    <QServiceLayout title={t('activerecord.attributes.layout.qoyod_academy')} actionBtns={null} breadCrumbData={breadCrumbData} >
      <Row gutter={[32, 32]}>
        <Col md={12}>
          <Flex vertical justify='center' style={{ height: '100%' }}>
            <Title level={4}>{t('status.soon')}..</Title>
            <Paragraph style={{ textAlign: 'justify' }}>
              {t('activerecord.attributes.professional_services.qoyod_academy.description')}
            </Paragraph>
          </Flex>
        </Col>
      </Row>
    </QServiceLayout>
  )
}

export default QoyodAcademy