import { Form, Checkbox, Col, Row, Skeleton } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getConfiguration } from 'api/dimensions';
import { CONFIGURATION_OPTIONS as options } from 'tokens/dimensions';


const localesMap = new Map([
  ['ManualJournalEntry', 'activerecord.attributes.commercial_document.mje'],
  ['EasyEntry', 'activerecord.attributes.commercial_document.easy_entry'],
  ['PurchaseOrder', 'activerecord.attributes.commercial_document.purchase_order'],
  ['Bill', 'activerecord.attributes.commercial_document.bill'],
  ['SimpleBill', 'activerecord.attributes.commercial_document.simple_bill'],
  ['DebitNote', 'activerecord.attributes.commercial_document.debit_note'],
  ['Quotation', 'activerecord.attributes.commercial_document.quotation'],
  ['Invoice', 'activerecord.attributes.commercial_document.invoice'],
  ['CreditNote', 'activerecord.attributes.commercial_document.credit_note'],
  ['InventoryTransfer', 'activerecord.attributes.commercial_document.inventory_transfer'],
  ['StockTake', 'activerecord.attributes.commercial_document.stock_take'],
  ['Production', 'activerecord.attributes.commercial_document.production'],
  ['Payroll', 'activerecord.attributes.payroll.payroll'],
  ['EmployeePayment', 'activerecord.attributes.employee_payment.employee_payment'],
  ['Loan', 'activerecord.attributes.loan.loan'],
  ['Bonus', 'activerecord.attributes.bonus.bonus'],
  ['Deduction', 'activerecord.attributes.deduction.deduction']
])

interface ConfigurationOptionsProps {
  dimensionID?: string;
  setCheckedList: React.Dispatch<React.SetStateAction<unknown[]>>;
  checkedList: unknown[]
}

const ConfigurationOptions = ({ dimensionID, setCheckedList, checkedList }: ConfigurationOptionsProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const { isLoading } = useQuery<{model: string}[]>({
    queryKey: ['configurations', dimensionID],
    queryFn: getConfiguration.bind(null, { dimensionID: String(dimensionID) }),
    enabled: Boolean(dimensionID),
    onSuccess: (data) => {
      if(data?.length) {
        const readyData = data.map(val => val.model);
        if(readyData.length === options.length) {
          readyData.push("");
        }
        setCheckedList(readyData);
      }
    }
  })

  useEffect(() => {
    form.setFieldValue("dimension_configuration", [...checkedList]);
  }, [ checkedList ])  
  
  return (
    <Form.Item wrapperCol={{xs: {span: 24}, lg: {offset: 3, span: 21}}} name="dimension_configuration">
      {isLoading? (
        <Skeleton active />
      ) : (
        <Checkbox.Group style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={12} md={8}>
              <Checkbox value="">
                {t('forms.placeholders.select_all')}
              </Checkbox>
            </Col>
            {options.map((entity, idx) => (
              <Col xs={12} md={8} key={`check${idx}`}>
                <Checkbox style={{fontWeight: 'normal'}} value={entity}>{t(localesMap.get(entity)!)}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      )}
    </Form.Item>
  )
}

export default ConfigurationOptions