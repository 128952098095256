import { Flex, Space, Typography } from "antd";

interface Props {
  icon: any;
  text: string;
}

export const FixedAssetAdditionCard = ({ icon, text }: Props) => {
  const { Text } = Typography;

  return (
    <Flex
      style={{
        backgroundColor: "#f4f4f4",
        border: "1px solid #e2e2e2",
        height: "175px",
        width: "175px",
        color: "#234189",
      }}
      justify="center"
      align="center"
    >
      <Space direction="vertical" align="center">
        {icon}
        <Text style={{ marginTop: "25px", fontSize: "16px", display: "block" }}>
          {text}
        </Text>
      </Space>
    </Flex>
  );
};
